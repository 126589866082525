import {ProfileSystemIcon} from '@nib/icons';
import {getBaseUrl, getLogoUrl} from './utils';
import {HeaderConfigType, GetConfigParams} from './types';

const LOGO_URL = '/';

const PHONE_NUMBER = '0800123642';
const CORPORATE_PHONE_NUMBER = null;

// My Account variables:
const MY_ACCOUNT_ID = 'ga-nav-nz-my-account';
const MY_ACCOUNT_URL = 'https://www.nib.co.nz/Portal';
const QUOTE_URL = 'https://join.nib.co.nz/quick-quote';
const BUSINESS_URL = 'https://health.nib.co.nz/business ';
const ABSOLUTE_BASEURL = 'https://www.nib.co.nz';

const getNzConfig = (config: GetConfigParams): HeaderConfigType => {
  const baseUrl = getBaseUrl(config, ABSOLUTE_BASEURL);
  const logoUrl = getLogoUrl(config, baseUrl, LOGO_URL);
  return {
    id: 'header-nz',
    logoUrl: logoUrl,
    hideQuoteButton: false,
    quoteButtonUrl: QUOTE_URL,
    ribbon: [
      {
        id: 'ga-secnav-nz-for-employers',
        title: 'For Employers',
        url: BUSINESS_URL
      },
      {
        id: 'ga-secnav-nz-for-health-partners',
        title: 'For Providers',
        url: 'https://provider.nibfirstchoice.co.nz'
      },
      {
        id: 'ga-secnav-nz-for-advisers',
        title: 'For Advisers',
        url: `${baseUrl}/adviser`
      },
      {
        id: 'ga-secnav-nz-health-insurance-cn',
        title: '中文',
        url: `${baseUrl}/offer/health-insurance-cn`
      }
    ],
    callToAction: {
      id: MY_ACCOUNT_ID,
      title: 'My nib',
      url: MY_ACCOUNT_URL
    },
    navLinks: [
      {
        id: 'ga-nav-nz-our-products',
        title: 'Our products',
        url: `${baseUrl}/our-products`,
        children: [
          {
            id: 'ga-nav-nz-health-insurance',
            title: 'Health insurance',
            url: `${baseUrl}/health-insurance`,
            children: [
              {
                id: 'ga-nav-nz-compare-plans',
                title: 'Compare plans',
                url: `${baseUrl}/compare-plans`
              },
              {
                id: 'ga-nav-nz-special-offers',
                title: 'Special offers',
                url: `${baseUrl}/special-offers`
              },
              {
                id: 'ga-nav-nz-adviser-plans',
                title: 'Cover through an adviser',
                url: `${baseUrl}/adviser-plans`
              },
              {
                id: 'ga-nav-nz-for-your-empolyees',
                title: 'Cover for your employees',
                url: BUSINESS_URL
              }
            ]
          },
          {
            id: 'ga-nav-nz-life-and-living-insurance',
            title: 'Life and living insurance',
            url: 'https://health.nib.co.nz/life-and-living-insurance',
            children: [
              {
                id: 'ga-nav-nz-life-cover-options',
                title: 'Cover options',
                url: `${baseUrl}/life-cover-options`
              }
            ]
          },
          {
            id: 'ga-nav-nz-travel-insurance',
            title: 'Travel insurance',
            url: 'https://www.nibtravel.co.nz/'
          },
          {
            id: 'ga-nav-nz-overseas-visitors-insurance',
            title: 'Overseas Visitors Insurance',
            url: 'https://orbitprotect.com/'
          }
        ]
      },
      {
        id: 'ga-nav-nz-using-your-cover',
        title: 'Using your cover',
        url: `${baseUrl}/using-your-cover`,
        children: [
          {
            id: 'ga-nav-nz-am-i-covered',
            title: "What you're covered for",
            url: `${baseUrl}/am-i-covered`
          },
          {
            id: 'ga-nav-nz-getting-health-treatment',
            title: 'Find a Provider',
            url: `${baseUrl}/find-a-provider`
          },
          {
            id: 'ga-nav-nz-claims',
            title: 'Claiming on your health cover',
            url: `${baseUrl}/claims`
          },
          {
            id: 'ga-nav-nz-claims-life-living',
            title: 'Claim on your life and living cover',
            url: 'https://health.nib.co.nz/contact-us/life-and-living-claims'
          },
          {
            id: 'ga-nav-nz-symptom-checker',
            title: 'Learn about my nib',
            url: 'https://health.nib.co.nz/mynib'
          }
        ]
      },
      {
        id: 'ga-nav-nz-health-and-wellbeing',
        title: 'Health and wellbeing',
        url: `${baseUrl}/health-and-wellbeing`,
        children: [
          {
            id: 'ga-nav-nz-health-management-programmes',
            title: 'Health Management Programmes',
            url: `${baseUrl}/health-management-programmes`,
            children: [
              {
                id: 'ga-nav-nz-symptom-checker',
                title: 'Symptom Checker',
                url: `https://health.nib.co.nz/symptom-checker`
              }
            ]
          },
          {
            id: 'ga-nav-nz-member-stories',
            title: 'Member stories',
            url: `${baseUrl}/member-stories`
          },
          {
            id: 'ga-nav-nz-articles-and-resources',
            title: 'Articles and resources',
            url: `${baseUrl}/free-resources`
          }
        ]
      },
      {
        id: 'ga-nav-nz-help-centre',
        title: 'Help centre',
        url: 'https://health.nib.co.nz/contact-us'
      },
      {
        id: 'ga-nav-nz-about-nib',
        title: 'About nib',
        url: `${baseUrl}/about-nib`,
        children: [
          {
            id: 'ga-nav-nz-about-nib-in-the-community',
            title: 'nib in the community',
            url: `${baseUrl}/about-nib/nib-in-the-community`
          },
          {
            id: 'ga-nav-nz-about-financial-advice',
            title: 'Financial advice and strength',
            url: `${baseUrl}/about-nib/financial-advice`
          },
          {
            id: 'ga-nav-nz-about-directors',
            title: 'Directors',
            url: `${baseUrl}/about-nib/directors`
          },
          {
            id: 'ga-nav-nz-about-news-and-media',
            title: 'News and media',
            url: `${baseUrl}/news-and-media`
          },
          {
            id: 'ga-nav-nz-careers',
            title: 'Careers',
            url: 'https://careers.nib.co.nz/'
          }
        ]
      }
    ],
    smallHeaderConfig: {
      id: 'small-header-nz',
      logoUrl: logoUrl,
      phoneNumber: PHONE_NUMBER,
      corporatePhoneNumber: CORPORATE_PHONE_NUMBER,
      navLinks: [
        {
          id: MY_ACCOUNT_ID,
          label: 'Login',
          title: 'Login',
          icon: ProfileSystemIcon,
          url: MY_ACCOUNT_URL
        }
      ]
    }
  };
};

export default getNzConfig;
