import {ProfileSystemIcon} from '@nib/icons';
import {getBaseUrl, getLogoUrl} from './utils';
import {HeaderConfigType, GetConfigParams} from './types';

const LOGO_URL = '/';
const PHONE_NUMBER = '13 16 42';
const CORPORATE_PHONE_NUMBER = '1800 13 14 63';

// My Account variables:
const MY_ACCOUNT_ID = 'ga-nav-my-account';
const MY_ACCOUNT_TITLE = 'Login';
const MY_ACCOUNT_URL = 'onlineservices';
const ABSOLUTE_BASEURL = 'https://www.nib.com.au';

const getArhiConfig = (config: GetConfigParams): HeaderConfigType => {
  const baseUrl = getBaseUrl(config, ABSOLUTE_BASEURL);
  const logoUrl = getLogoUrl(config, baseUrl, LOGO_URL);
  return {
    id: 'header-arhi',
    phoneNumber: PHONE_NUMBER,
    corporatePhoneNumber: CORPORATE_PHONE_NUMBER,
    logoUrl: logoUrl,
    ribbon: [],
    callToAction: {
      id: 'ga-nav-get-a-quote',
      title: 'Get a quote',
      url: `${baseUrl}/health-insurance/join/welcome`
    },
    navLinks: [
      {
        id: 'ga-nav-health-insurance',
        title: 'Health insurance',
        url: `${baseUrl}/health-insurance`,
        children: [
          {
            id: 'ga-nav-hi-singles',
            title: 'Singles',
            url: `${baseUrl}/health-insurance/singles`
          },
          {
            id: 'ga-nav-hi-couples',
            title: 'Couples',
            url: `${baseUrl}/health-insurance/couples`
          },
          {
            id: 'ga-nav-hi-families',
            title: 'Family',
            url: `${baseUrl}/health-insurance/family`
          },
          {
            id: 'ga-nav-hi-extras',
            title: 'Extras',
            url: `${baseUrl}/health-insurance/extras`
          },
          {
            id: 'ga-nav-hi-hospital',
            title: 'Hospital',
            url: `${baseUrl}/health-insurance/hospital`
          },
          {
            id: 'ga-nav-hi-ambulance',
            title: 'Ambulance',
            url: `${baseUrl}/health-insurance/ambulance`
          },
          {
            id: 'ga-nav-overseas-students',
            title: 'Overseas students (OSHC)',
            url: `${baseUrl}/overseas-students`
          },
          {
            id: 'ga-nav-overseas-visitors',
            title: 'Overseas visitors (OVHC)',
            url: `${baseUrl}/overseas-working-visitors`
          },
          {
            id: 'ga-nav-hi-understand',
            title: 'Understanding Hospital & Extras cover',
            url: `${baseUrl}/health-information`
          },
          {
            id: 'ga-nav-hi-incentives',
            title: 'Lifetime health cover',
            url: `${baseUrl}/health-information/healthcare-in-australia/lifetime-health-cover`
          },
          {
            id: 'ga-nav-hi-member-benefits',
            title: 'Member benefits',
            url: `${baseUrl}/member-benefits`
          }
        ]
      },
      {
        id: 'ga-nav-travel-insurance',
        title: 'Travel insurance',
        url: 'https://www.nibtravelinsurance.com.au'
      },
      {
        id: 'ga-nav-ndis-services',
        title: 'NDIS services',
        url: `${baseUrl}/thrive`
      },
      {
        id: 'ga-nav-find-a-provider',
        title: 'Find a Provider',
        url: `${baseUrl}/find-a-provider`
      },
      {
        id: 'ga-nav-healthy-living',
        title: 'Healthy Living',
        url: `${baseUrl}/healthy-living`,
        children: [
          {
            id: 'ga-nav-hl-health-management-programs',
            title: 'Health Management Programs',
            url: `${baseUrl}/health-management-programs`
          },
          {
            id: 'ga-nav-hl-healthcheck',
            title: 'HealthCheck',
            url: `${baseUrl}/healthy-living/healthcheck`
          },
          {
            id: 'ga-nav-hl-the-checkup',
            title: 'The Check Up: wellness articles',
            url: `${baseUrl}/the-checkup`
          },
          {
            id: 'ga-nav-hl-greenpass',
            title: 'Join nib for free',
            url: `${baseUrl}/healthy-living/greenpass`
          },
          {
            id: 'ga-nav-hl-telehealth',
            title: 'Telehealth',
            url: `${baseUrl}/health-information/gp-telehealth`
          }
        ]
      },
      {
        id: 'ga-nav-help',
        title: 'Help',
        url: `${baseUrl}/help`,
        positionRight: true
      },
      {
        id: MY_ACCOUNT_ID,
        title: MY_ACCOUNT_TITLE,
        url: `${baseUrl}/${MY_ACCOUNT_URL}`
      }
    ],
    smallHeaderConfig: {
      id: 'small-header-arhi',
      logoUrl: logoUrl,
      phoneNumber: PHONE_NUMBER,
      corporatePhoneNumber: CORPORATE_PHONE_NUMBER,
      navLinks: [
        {
          id: MY_ACCOUNT_ID,
          label: MY_ACCOUNT_TITLE,
          title: MY_ACCOUNT_TITLE,
          icon: ProfileSystemIcon,
          url: `${baseUrl}/${MY_ACCOUNT_URL}`
        }
      ]
    }
  };
};

export default getArhiConfig;
